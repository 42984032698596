import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter,Redirect } from "react-router-dom";

import axios from 'axios'
import moment from 'moment-timezone'
import querySearch from 'stringquery'
import * as actions from "./actions/actions";

import RouteConfig from "./RouteConfig";
import EnvVariables from "./assets/EnvVariable"
import BufferingImage from "./images/Buffering.gif";
import { Container,Grid,Breadcrumb,Ref,Dimmer,Image } from "semantic-ui-react";

const mapDispatchToProps = dispatch => {
  return {
    fetchBuildingInfoRequest: accountNumber =>
      dispatch(actions.fetchBuildingInfoRequest(accountNumber)),
    fetchBuildingInfoSuccess: data =>
      dispatch(actions.fetchBuildingInfoSuccess(data)),
    fetchBuildingInfoError: error =>
      dispatch(actions.fetchBuildingInfoError(error))
  };
};

export class App extends Component {
  constructor(props) {
    super(props);
    this.focussibleElements = [];
    const params = querySearch(window.location.search)
    console.log("queryParams ",window.btoa(JSON.stringify(params)));
    this.state = {
      time : "",
      params : params,
      redirectTo : "",
      displayHeader: true,
      isFullScreen : false,
      loadComplete : false
    };
  }

  /**
 * Check to see if the x1 bridge is loaded and available for use
 *
 */
  loadedCallback = () => {
    console.log("bridge is Active")
    window.$badger.logger.info("moneyBadger loaded")
    if(this.state.loadComplete) {
      window.$badger.launchCompletedMetricsHandler();
    }
  };

  /**
  *  update the timeZone
  */
  updateTime = (timeZone="America/New_York") => {
    let time = moment().tz(timeZone).format("hh:mm A");
    this.setState({time});
  };

  componentDidMount = () => {
    if(window.$badger.active()){
      this.loadedCallback();
     }else {
       window.document.addEventListener(
         'onMoneyBadgerReady',
          this.loadedCallback
       );
     }
     if (this.focussibleElements.length > 0) {
       this.focussibleElements[0].focus();
     }
     const params = querySearch(window.location.search) || {}
     this.updateTime(window.decodeURIComponent(params.timeZone));
     const account_number = params.accountId || "1111"
     window.$badger.logger.info("accountId = " + window.btoa(params.accountId))
     const serviceAccessToken = params.serviceAccessToken || "";
     window.$badger.logger.info("serviceAccessToken = " + window.btoa(params.serviceAccessToken))
     const modeEnv = (EnvVariables[window.location.hostname] || EnvVariables.default);
     const url = `${modeEnv.url}/client/mdu/x1/camerafeed`
     this.props.fetchBuildingInfoRequest();
     window.$badger.userActionMetricsHandler(`making call to ${url} at ${new Date()}`)
     axios.get(
        url,
      {
        timeout: 30000,
        headers : {
          method : "GET",
          "Authorization" : "Bearer " + serviceAccessToken,
          "Access-Control-Allow-Origin": "*"
        }
      }
    )
    .then(response => {
      window.$badger.userActionMetricsHandler(`APi Response Receieved at ${new Date()}`,response.data); 
      console.log("Xh_fingerprint = " + response.headers["xh_fingerprint"])
      window.$badger.logger.info("Xh_fingerprint = " + response.headers["xh_fingerprint"]);
      const data = (response.data && response.data.result) || {};
        this.props.fetchBuildingInfoSuccess(data);
        // window.$badger.launchCompletedMetricsHandler();
        // this.setState({loadComplete:true});
    })
      .catch(error => {
        // window.$badger.launchCompletedMetricsHandler();
        // this.setState({loadComplete:true});
        this.props.fetchBuildingInfoError(error);
        if(!error.response){
          return;
        }
        console.log("Xh_fingerprint = " + error.response.headers["xh_fingerprint"])
        window.$badger.logger.info("Xh_fingerprint = " + error.response.headers["xh_fingerprint"]);
        window.$badger.userErrorMetricsHandler("Failed to Load Api ",false,"USER-001",error);
        window.$badger.logger.info("Error Occured" , error)
        const status = error.response.status;
        if (status===403){
          this.setState({redirectTo:"/upsell"});
          window.$badger.logger.info("XC App launched with upsellPage",status)
        }
      })
      .finally(() => {
        // always executed
        window.$badger.launchCompletedMetricsHandler();
        this.setState({loadComplete:true});
        window.$badger.logger.info("XC App loadCompleted")
      });
 };

  componentDidUpdate() {
    this.onRouteChange(this.props.history);
    window.$badger.logger.info("Route changed")
  }

  componentWillUnmount () {
    document.getElementById("cacvContainer").removeEventListener("keydown",this.changeFocus);
  }

/**
 * focus on Elements after route change
 *
 */
  onRouteChange = history => {
    this.focussibleElements = Array.from(
      document.querySelectorAll(
        '[tabindex]:not([tabindex="-1"])'
      )
    );
    if (this.focussibleElements.length > 1) {
      this.focussibleElements[1].focus();
    }
    if(!history) {
      return;
    }
    if(history.location.pathname === "/error" || history.location.pathname === "/upsell" || history.location.pathname === "/video"){
      this.setState({displayHeader: false});
    } else {
      this.setState({displayHeader: true});
    }
    if(history.location.pathname === "/video"){
      this.setState({isFullScreen: true});
    } else {
      this.setState({isFullScreen: false});
    }
    this.history = history;
  };

/**
 * highlight the focussedElement on page
 *
 */
  isVisible = function(elem) {
    return !!(
      elem.offsetWidth ||
      elem.offsetHeight ||
      elem.getClientRects().length
    );
  };

/**
 * focus change on the page between elements 
 *
 */
  changeFocus = evt => {
    if(window.videoPlaying) {
      return;
    } 
    evt.stopPropagation();
    evt.preventDefault();
    window.$badger.logger.info("Focus changed")
    let currentIndex = this.focussibleElements.indexOf(evt.target);
    if (currentIndex === -1) {
      if (this.focussibleElements.length > 0) {
        this.focussibleElements[0].focus();
      }
      return;
    }
    switch (evt.keyCode) {
      case 37:
        if (currentIndex === 0) {
          return;
        }
        while (
          !this.isVisible(this.focussibleElements[--currentIndex]) &&
          currentIndex > 0
        ) {}
        this.focussibleElements[currentIndex].focus();
        break;
      case 38:
        if (evt.target.getAttribute("data-up-arrow-target") && document.getElementById(evt.target.getAttribute("data-up-arrow-target"))) {
          document.getElementById(evt.target.getAttribute("data-up-arrow-target")).focus();
        } else {
          if (currentIndex === 0) {
            return;
          }
          while (
            !this.isVisible(this.focussibleElements[--currentIndex]) &&
            currentIndex > 0
          ) {}
          this.focussibleElements[currentIndex].focus();
        }
        break;
      case 39:
        this.focusNext(currentIndex);
        break;
      case 40:
        if (evt.target.getAttribute("data-down-arrow-target") && document.getElementById(evt.target.getAttribute("data-down-arrow-target"))) {
          document.getElementById(evt.target.getAttribute("data-down-arrow-target")).focus();
        } else {
          this.focusNext(currentIndex);
        }
        break;
      case 13:
          evt.target.click();
        break;
      case 8:
      case 27:
        //window.$badger.logger.info(`Current location: ${JSON.stringify(window.location)}`);
        if (window.location && (window.location.pathname === "/error" || window.location.pathname === "/video")) {
              window.history.back();
          } else {
              window.$badger.shutdown();
              window.$badger.logger.info("from App js","exitButton initiated")
          }
        break;
      default:
        console.log("Other key code: " + evt.keyCode);
    }
  };

/**
 * decision on next focussed element based on index
 *
 */
  focusNext = currentIndex => {
    if (currentIndex === this.focussibleElements.length - 1) {
      return;
    }
    while (!this.isVisible(this.focussibleElements[++currentIndex])) {}
    this.focussibleElements[currentIndex].focus();
  };

  render() {
    const { time,redirectTo,displayHeader=true,isFullScreen=false } = this.state;
    const { isLoading } = this.props;

    return (
      <BrowserRouter getUserConfirmation={this.onRouteChange}>
        <Container onKeyDown={this.changeFocus} style={{"minHeight":"100%"}} id="cacvContainer">
        {redirectTo && <Redirect to = {redirectTo}/>}
          <Dimmer active={isLoading}>
            <Image src={BufferingImage} width={146}></Image>
          </Dimmer>
          <div className={!isFullScreen ? "p-xl" : ""}>
              {displayHeader && <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Breadcrumb>
                      <Ref
                        innerRef={node => {
                          this.focussibleElements[0] = node;
                        }}
                      >
                        <Breadcrumb.Section tabIndex="0" id="apps">
                          <span>Apps /</span>
                        </Breadcrumb.Section>
                      </Ref>
                      <Breadcrumb.Divider />
                      <Breadcrumb.Section active color="blue">
                        Xfinity Communities
                      </Breadcrumb.Section>
                    </Breadcrumb>
                  </Grid.Column>
                  <Grid.Column textAlign="right">{time}</Grid.Column>
                </Grid.Row>
              </Grid>}
              <RouteConfig onRouteChange={this.onRouteChange}/>
           </div>
        </Container>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.isLoading,
    isError: state.isError,
    buildingInfo: state.buildingInfo
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(App);


// button, [href]:not(link), input, select, textarea, // moment.tz.guess() {temp ? " / " + temp + "°F": ""}