
const EnvVariables = {
    "dev.mdu.xfinity.com":{
        url : "https://xhomeapi-preview-prod.apps.cloud.comcast.net"
    },
    "tps.mdu.xfinity.com":{
        url : "https://xhomeapi-tps.apps.cloud.comcast.net"
    },
    "qa.mdu.xfinity.com":{
        url : "https://xhomeapi-qamdu.po-b1.cf.comcast.net"
    },
    "prod.mdu.xfinity.com":{
        url : "https://xhomeapi-lb-prod.apps.cloud.comcast.net"
    },
    "localhost":{
        url : "https://xhomeapi-qamdu.b4.app.cloud.comcast.net"
    },
    default : {
        url : "https://xhomeapi-tps.apps.cloud.comcast.net"
    }
} 

export default EnvVariables;