import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Link } from "react-router-dom"

import { Image, Dimmer } from "semantic-ui-react";

import * as Utils from "../assets/Utils";
import BufferingImage from "../images/Buffering.gif";
    
export class VideoThumbNail extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading : false
        }
    }

    componentWillUnmount() {
        this.setState({isLoading:false});
    }
    
    /* getting camera url from Payload */
    gotoVideo = () => {
        this.setState({isLoading:true})
        if(!this.props.camera.url) {
            window.$badger.logger.info(`${Utils.URL_MISSING.errorCode}: ${Utils.URL_MISSING.message}`);
            this.props.history.push("/error");
            return;
        }
        window.$badger.logger.info("user click on thumbnail , " + this.props.camera.name);
        window.$badger.logger.info("user click on video");
        //this.props.history.push("/video?radiantUrl="+encodeURIComponent(url));
        //window.$badger.logger.info(`Current location: ${JSON.stringify(window.location)}`);
    }

    render() {
        const { thumbnail,name,id,upTarget,downTarget } = this.props;
        const { isLoading } = this.state;
        let url = new URL(this.props.camera.url);
        url = url.searchParams.get("feed");
        return (
            <Link tabIndex={-1} to={"/video?radiantUrl="+encodeURIComponent(url)} className="column video-column">
                <Dimmer active={isLoading} page={true}>
                    <Image src={BufferingImage} width={146}></Image>
                </Dimmer>
                <div tabIndex={0} data-up-arrow-target={upTarget} data-down-arrow-target={downTarget}
                        className="v-wrapper" onClick={this.gotoVideo} id={id}>
                    <Image src={thumbnail}/>
                     <div className="videoName-wrapper">
                        <div className="videoName"> 
                            {name}
                        </div>
                     </div> 
                </div>
            </Link>
        );
    }
}

export default withRouter(VideoThumbNail);