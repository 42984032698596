import React, { Component } from "react";
import { Redirect } from 'react-router-dom';

import SpinnerImage from "../images/Spinner.gif";
import { Dimmer, Image } from "semantic-ui-react";
import BufferingImage from "../images/Buffering.gif";

import querySearch from 'stringquery'
import AAMPPlayer from "../components/Player"

const playerStatesEnum = { "idle":0, "initializing":1, "playing":8, "paused":6, "seeking":7 };
    Object.freeze(playerStatesEnum);

const defaultInitConfig = {
        /**
         * preferred audio language
         */
        preferredAudioLanguage: "en",
    
        /**
         * TSB length in seconds, value of 0 means it is disabled
         */
        //timeShiftBufferLength: number;
    
        /**
         * offset from live point for live assets (in secs)
         */
        liveOffset: 1,
    };

export default class VideoPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            isBuffering : false,
            isLoading : true,
            isError : false
        }
    }
    
/*
 * Adding Style to the Body
 */
componentDidMount () {
    document.addEventListener("keydown", this.listenForPlayerKeys);
    document.getElementById("dummyVideo").focus();
    const params = querySearch(window.location.search);
    let url = decodeURIComponent(params.radiantUrl);
    this.player = new AAMPPlayer(document.getElementById("dummyVideo"));
    this.player.addEventListener("playbackStarted", this.mediaPlaybackStarted);
    this.player.addEventListener("contentStarted", this.mediaPlaybackStarted);
    this.player.addEventListener("playbackStateChanged", this.playbackStateChanged);
    this.player.addEventListener("contentCompleted", this.mediaEndReached);
    this.player.addEventListener("playbackCompleted", this.mediaEndReached);
    this.player.addEventListener("playbackSpeedChanged", this.mediaSpeedChanged);
    this.player.addEventListener("playbackFailed", this.mediaPlaybackFailed);
    this.player.addEventListener("bufferingChanged", this.mediaPlaybackBuffering);
    this.player.addEventListener("durationChanged", this.mediaDurationChanged);
    this.player.initConfig(defaultInitConfig);
    this.player.load(url);
    this.player.play();
 }

 /*
 * Removing of Style from the Body
 */
 componentWillUnmount () {
    if (!this.player) {
        return;
    }
    document.removeEventListener("keydown", this.listenForPlayerKeys);
    this.player.removeEventListener("playbackStarted", this.mediaPlaybackStarted);
    this.player.removeEventListener("contentStarted", this.mediaPlaybackStarted);
    this.player.removeEventListener("playbackStateChanged", this.playbackStateChanged);
    this.player.removeEventListener("contentCompleted", this.mediaEndReached);
    this.player.removeEventListener("playbackCompleted", this.mediaEndReached);
    this.player.removeEventListener("playbackSpeedChanged", this.mediaSpeedChanged);
    this.player.removeEventListener("playbackFailed", this.mediaPlaybackFailed);
    this.player.removeEventListener("bufferingChanged", this.mediaPlaybackBuffering);
    this.player.removeEventListener("durationChanged", this.mediaDurationChanged);
    this.releasePlayer();
 }

 /* checking for playback started */
 mediaPlaybackStarted = (evt) => {
    window.$badger.logger.info("ON PLAYING");
    this.setState({isLoading:false})
    window.videoPlaying = true;
}

/* checking for playback state */
playbackStateChanged = (evt) => {
    window.$badger.logger.info("ON STATE CHANGED");
    switch (evt.state) {
        case playerStatesEnum.idle:
            this.playerState = playerStatesEnum.idle;
            break;
        case playerStatesEnum.initializing:
            this.playerState = playerStatesEnum.initializing;
            break;
        case playerStatesEnum.playing:
            this.playerState = playerStatesEnum.playing;
            break;
        case playerStatesEnum.paused:
            this.playerState = playerStatesEnum.paused;
            break;
        case playerStatesEnum.seeking:
            this.playerState = playerStatesEnum.seeking;
            break;
        default:
            console.log("State not expected");
            break;
    }
}

/* checking for playback completed */
mediaEndReached = (evt) => {
    window.$badger.logger.info("ON ENDED");
    this.setState({isLoading:false,isError:true});
    if(this.player) {
        this.player.stop();
        window.videoPlaying = false;
        this.player.destroy();
        this.player = null;
        document.removeEventListener("keydown", this.listenForPlayerKeys);
    }
}

/* checking for playback speed */
mediaSpeedChanged = (evt) => {
    window.$badger.logger.info("ON SPEED CHANGED")
}

/* checking for playback errors */
mediaPlaybackFailed = (evt) => {
    window.$badger.logger.info("ON ERROR");
    this.setState({isLoading:false,isError:true});
    if(this.player) {
        this.player.stop();
        window.videoPlaying = false;
        this.player.destroy();
        this.player = null;
        document.removeEventListener("keydown", this.listenForPlayerKeys);
    }
}
  
 /* checking for playback buffering or playing*/
 mediaPlaybackBuffering = (isBuffering) => {
    window.$badger.logger.info("ON BUFFERING CHANGED")
    this.setState({isBuffering})
}

 /* checking for playback duration changed or not*/
 mediaDurationChanged = (evt) => {
    //window.$badger.logger.info("ON DURATION CHANGED")
}

/* releasing the player after exits from video page */
releasePlayer = (evt) => {
    if(this.player) {
        this.player.stop();
        window.videoPlaying = false;
        this.player.destroy();
        this.player = null;
        if(evt){
            evt.stopPropagation();
            evt.preventDefault();
        } 
        document.removeEventListener("keydown", this.listenForPlayerKeys);
    }
}
        
 /* listen to player key events */
 listenForPlayerKeys = (evt) => {
    console.log(evt.keyCode);
    switch (evt.keyCode) {
        case 80:
            if(this.playerState === playerStatesEnum.playing) {
                this.player.pause();
            } else {
                this.player.play();
            }
            evt.stopPropagation();
            evt.preventDefault();
            break;
        case 8:
        case 27:
            this.releasePlayer(evt);
            break;
        default:
            console.log("Other key code: " + evt.keyCode);
    }
}

    render() {
         const { isBuffering,isLoading,isError } = this.state;
        return(
            <div>
                {isError && <Redirect to={"/error"}/>}
                <Dimmer active={isBuffering || isLoading}>
                    {isLoading && <Image src={BufferingImage} width={146}></Image>}
                    {isBuffering && <Image src={SpinnerImage} width={80}></Image>}
                </Dimmer>
                <video id="dummyVideo" tabIndex={0} className="videoPlayer" autoplay="autoplay">
                    <source src="placeholder.mp4" type="video/ave"/>
                </video>
            </div>
        )
    }
}