import React, { PureComponent } from "react";
import UpSellBgimg from "../images/XfinityQuote.jpg";

const UpSellBg = {
    backgroundImage : `url(${UpSellBgimg})`,
    height : "100%",
    backgroundPosition : "center",
    backgroundRepeat : "no-repeat",
    backgroundSize : "cover"
}

export default class ErrorPage extends PureComponent {

/*
 * Adding Style to the Body
 */
componentDidMount () {
    document.body.style.backgroundImage = UpSellBg.backgroundImage;
    document.body.style.height = UpSellBg.height;
    document.body.style.backgroundPosition = UpSellBg.backgroundPosition;
    document.body.style.backgroundRepeat = UpSellBg.backgroundRepeat;
    document.body.style.backgroundSize = UpSellBg.backgroundSize;
    document.getElementById("UpSell").focus()
 }

 /*
 * Removing of Style from the Body
 */
 componentWillUnmount () {
    document.body.style.backgroundImage = "";
    document.body.style.height = "";
    document.body.style.backgroundPosition = "";
    document.body.style.backgroundRepeat = "";
    document.body.style.backgroundSize = "";
 }

    render() {
        return(
            <div className="error-wrapper" id="UpSell" tabIndex={0}>
            </div>
        )
    }
}
