import React, { PureComponent } from "react";
import { Header,Container } from "semantic-ui-react";
import {withRouter} from 'react-router-dom';

import "./Error.css"

class ErrorPage extends PureComponent {

componentDidMount () {
    document.getElementById("errorOkBtn").focus();
}

/* goback on ok button click */
goBack = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    const url = new URL(window.location);
    const quit = url.searchParams.get('quit');
    switch (evt.keyCode) {
        case 45 :
                window.$badger.shutdown();
                window.$badger.logger.info("from errorPage","exitButton initiated")
        break;
        default :
            if(quit === "1"){
                window.$badger.shutdown();
                window.$badger.logger.info("from errorPage","exitButton initiated")
            } else {
                this.props.history.push("/");
            }
        break;
    }
}
    render() {
        return(
            <div className="error-wrapper" id="errorMessage">
                <div className="column twelve wide">
                   <Header as="h2" color="blue" textAlign="left" className="msg-header">
                       Sorry we are having trouble
                   </Header>
                   <Container className="msg-wrapper" textAlign="center" style={{"color":"white"}}>
                    <div style={{textAlign:"justify"}}>
                        <span> 
                            Please wait a few minutes and try again. If the problem 
                            persists, please call 1-800-Xfinity. We apologize for any 
                            inconvenience.
                        </span>
                    </div>
                   </Container>
                   <div className="by-1" style={{width:"569px", marginTop: "1rem"}}>
                        <span tabIndex={0} id="errorOkBtn" color="blue" className="b-0 no-box-shadow" onKeyDown={this.goBack}> OK </span>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(ErrorPage);