import React, { Component } from "react";
import DashBoard from "./pages/DashBoard";
import { Switch, Route, withRouter } from "react-router-dom";
import ErrorPage from './pages/Error'
import UpSellPage from './pages/UpSell'
import VideoPage from "./pages/Video";


class RouteConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount () {
    this.routeChanged()
  }
  componentDidUpdate(prevProps) {
    let {
      location: { pathname }
    } = this.props;

    if (prevProps.location.pathname === pathname) return;
    this.routeChanged();
  }
  routeChanged = () => {
      this.props.onRouteChange(this.props.history);
  }
  render() {
    return (
      <Switch>
          <Route exact path="/" component={()=><DashBoard/>}/>
          <Route exact path="/video" component={()=><VideoPage/>}/>
          <Route exact path="/error" component={()=><ErrorPage/>}/>
          <Route exact path="/upsell" component={()=><UpSellPage/>}/>
      </Switch>
    );
  }
}

export default withRouter(RouteConfig);
