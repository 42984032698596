import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter,Redirect } from 'react-router-dom';

import moment from 'moment'
import Ca from "../images/ca.svg";
import * as Utils from "../assets/Utils";
import VideoThumbNail from "../components/VideoThumbNail";
import { Divider,Segment,Accordion,Icon,Image } from "semantic-ui-react";

import CameraImage from "../images/Camera.png";
import Whatsnew from "../images/Whatsnew.png";
import FrontGateImage from "../images/FrontGate.png";
import MailRoomImage from "../images/Mailroom.png";
import ParkingLotImage from "../images/ParkingLot.png";
import PoolCameraImage from "../images/PoolCamera.png";
import Announcements from "../images/Announcements.png";

import "./DashBoard.css"

export class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, activeCamera: 0 };
  }

  /* to display Default Thumbnails */
  getAnnouncementThumbNail = (announcement) => {
    if(announcement.thumbnail) {
      return announcement.thumbnail;
    }
    window.$badger.logger.info(`${Utils.THUMBNAIL_MISSING.errorCode}: ${Utils.THUMBNAIL_MISSING.message}`);
    if(!announcement.name) {
      window.$badger.logger.info(`${Utils.NAME_MISSING.errorCode}: ${Utils.NAME_MISSING.message}`);
    }
    return Announcements;
  }

  /* to display Default camera Image */
  getCameraImage = (camera) => {
    if(camera.thumbnail) {
      return camera.thumbnail;
    }
    window.$badger.logger.info(`${Utils.THUMBNAIL_MISSING.errorCode}: ${Utils.THUMBNAIL_MISSING.message}`);
    if(camera.name) {
      switch (camera.name) {
        case "Front Desk":
          return MailRoomImage;
        case "Building Entrance":
          return FrontGateImage;
        case "Parking Lot":
          return ParkingLotImage;
        case "Pool Camera":
          return PoolCameraImage;
        default :
          return CameraImage;
      }
    }
    window.$badger.logger.info(`${Utils.NAME_MISSING.errorCode}: ${Utils.NAME_MISSING.message}`);
    return CameraImage;
  }

  /* onclick event for thumbnail/camera */
  handleClick = (evt, { index }) => {
    this.setState({ activeIndex: index });
  };

  componentDidUpdate(prevProps, prevState) {
    const mappings={0:"announcements", 1:"cameras", 2:"xfinity_home"}
    if(prevState.activeIndex !== this.state.activeIndex &&
      (this.state.activeIndex === 1 ||  this.state.activeIndex === 0 || this.state.activeIndex === 2)) {
    let ele = document.querySelector('#'+mappings[this.state.activeIndex]+' .v-wrapper');
    if(ele) {
      ele.focus();
    }
    }
  }

  /* getting thumbnail for Announcements from Payload */
  gotoAnnouncements = (announcement) => {
    if(!announcement.url){
      window.$badger.logger.info(`${Utils.URL_MISSING.errorCode}: ${Utils.URL_MISSING.message}`);
      this.props.history.push("/error");
      return;
    }
    window.$badger.logger.info("Loading Billboards page", announcement.url)
    if(announcement && announcement.healthy) {
      window.location = announcement.url;
    }
  }

  /* to display error page */
  showErrorScreen = (evt) => {
    this.props.history.push("/error");
  }

  render() {
    let {  activeCamera=0 } = this.state;
    const {buildingInfo={}, isLoading, isError=false } = this.props;
    let {cameras=[], communityBillBoards=[], applications=[]} = buildingInfo;
    
    communityBillBoards = communityBillBoards.filter(ch=>{
      const today = moment().unix();
      if(parseInt(ch.dateFrom) <= today && today <= parseInt(ch.dateTo)) {
        return true;
      }
      return false
    }).sort((a,b)=>parseInt(a.order)-parseInt(b.order));

    const showBillboard = (applications.includes("Billboard") || communityBillBoards.length > 0);
    const showCamera = (applications.includes("Camera") || cameras.length > 0);
    const hasBillboards = communityBillBoards.length > 0;
    const hasCameras = cameras.length > 0;

    return (
      <Segment inverted className="p-0 m-t-0">
        { isError && <Redirect to={"/error?quit=1"}/> }
        <Accordion inverted>
          { showBillboard ? <React.Fragment>
          <Accordion.Title
          active={true}
          index={0}
          onClick={this.handleClick}
          className="fs-big"
        >
        <Divider/>
          <img src={Ca} alt="announcement" className="announcementIcon"/>
            Community Billboard 
        </Accordion.Title>
        <Accordion.Content active={true}>
          <div id="announcements" className="ui grid">
          {
            communityBillBoards.map((chnl, i) => {
              let up = "apps";
              let down = hasCameras ? "camera_0" : "";
              const total = communityBillBoards.length;
              if((i+5)<total) {
                down = "announcement_" + (i+5);
              } else {
                const position = i % 5;
                if(showCamera && position < cameras.length) {
                  down = "camera_" + position;
                }
              }
              if((i>4)){
                up = "announcement_" + (i-5);
              }
              return (<div key={chnl.name} tabIndex={0} id={"announcement_" + i} data-up-arrow-target={up} data-down-arrow-target={down} className="v-wrapper three wide column" onClick={()=>this.gotoAnnouncements(chnl)}>
                  <Image  src={this.getAnnouncementThumbNail(chnl)}/>
                  <div className="videoName-wrapper">
                    <div className="videoName"> 
                      {chnl.name}
                    </div>
                  </div>
              </div>)
            })
          }
          {!isLoading && communityBillBoards.length === 0 && isError && <div key="no_announcement" tabIndex={0} data-up-arrow-target="apps" data-down-arrow-target={hasCameras ? "camera_0" : ""}
          className="v-wrapper three wide column" onClick={(evt)=>this.showErrorScreen(evt)}>
              <Image src={Whatsnew}/>
              <div className="videoName-wrapper">
                <div className="videoName"> 
                  Whatsnew
                </div>
              </div>
          </div>}
        {!isLoading && communityBillBoards.length === 0 && !isError && <div className="noMessage"> No Current Billboards </div> }
        </div>
        </Accordion.Content>
        </React.Fragment> 
        : null
      }
        { showCamera 
          ? <React.Fragment>
              <Accordion.Title
                active={true}
                index={1}
                onClick={this.handleClick}
                className="fs-big cameratitle"
              >
              <Divider/>
                <Icon name="video camera" color="blue"/>
                  Community Cameras
              </Accordion.Title>
              <Accordion.Content active={true} className="ui grid">
                <div className="m-b five column row" id="cameras">
                  {cameras && cameras.map((item, i) => { 
                     let up = hasBillboards ? "announcement_0" : "apps";
                     let down = "xhomeTitle";
                     const total = cameras.length;
                     if((i+5)<total) {
                       down = "camera_" + (i+5);
                     }
                     if((i>4)){
                       up = "camera_" + (i-5);
                     } else if(hasBillboards) {
                        const comRows = Math.ceil(communityBillBoards.length / 5) - 1;
                        const currentRelativePosition = i % 5;
                        const estimatedUpPosition = comRows * 5 + currentRelativePosition + 1;
                        if (estimatedUpPosition <= communityBillBoards.length) {
                          up = "announcement_" + (estimatedUpPosition - 1);
                        } else {
                          up = "announcement_" + (communityBillBoards.length - 1);
                        }
                     }
                     return (
                      <VideoThumbNail key={item.name}
                        camera={item}
                        id={"camera_" + i}
                        thumbnail={this.getCameraImage(item)}
                        active={activeCamera === i}
                        name={item.name}
                        icon={item.icon}
                        url={item.url+""}
                        history={this.props.history}
                        autoPlay
                        upTarget={up}
                        downTarget={down}
                      />
                    )})}
                  {!isLoading && cameras && cameras.length === 0 && isError && <div tabIndex={0} data-up-arrow-target={hasBillboards ? "announcement_0" : "apps"} data-down-arrow-target="xhomeTitle"
                  className="v-wrapper three wide column" onClick={this.showErrorScreen}>
                      <Image  src={CameraImage}/>
                      <div className="videoName-wrapper">
                      <div className="videoName"> 
                        Camera
                      </div>
                      </div>
                  </div>}
                  {!isLoading && cameras && cameras.length === 0 && !isError &&<div className="noMessage"> No Current Cameras </div> }
                </div>
              </Accordion.Content>
            </React.Fragment>
          : null
        }
        </Accordion>
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.isLoading,
    isError: state.isError,
    buildingInfo: state.buildingInfo
  }
};

export default connect(mapStateToProps) (withRouter(DashBoard));
