
export const THUMBNAIL_MISSING = {
    errorCode: "THUMBNAIL_MISSING",
    message: "Thumbnail missing from Payload"
};

export const URL_MISSING = {
    errorCode: "URL_MISSING",
    message: "Url missing from Payload"
};

export const NAME_MISSING = {
    errorCode: "NAME_MISSING",
    message: "Name is missing from Payload"
};