export const fetchBuildingInfoRequest =() => {
    return {
      type: "GET_SITE_INFO"
    }
  }
  
  export const fetchBuildingInfoSuccess =(payload) => {
    return {
      type: "GET_SITE_INFO_SUCCESS",
      payload
    }
  }
  
  export const fetchBuildingInfoError = (payload) => {
    return {
      type: "GET_SITE_INFO_ERROR",
      payload
    }
  }